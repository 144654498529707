// import React from 'react';
// import { FormattedMessage } from '../../util/reactIntl';
// import { ListingCard } from '../../components';
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';

// import css from './SimilarListingsCarousel.module.css';

// const SimilarListingsCarousel = ({ similarListings, config }) => {
//   if (!similarListings || similarListings.length === 0) {
//     return null;
//   }

//   const responsive = {
//     desktop: {
//       breakpoint: { max: 3000, min: 1024 },
//       items: 4,
//       slidesToSlide: 3,
//     },
//     tablet: {
//       breakpoint: { max: 1024, min: 464 },
//       items: 4,
//       slidesToSlide: 2,
//     },
//     mobile: {
//       breakpoint: { max: 464, min: 0 },
//       items: 2.5,
//       slidesToSlide: 1,
//     },
//   };

//   return (
//     <div className={css.similarListingsSection}>
//       <h2 className={css.similarListingsTitle}>
//         <FormattedMessage id="ListingPage.similarListings" />
//       </h2>
//       <Carousel
//         responsive={responsive}
//         ssr={true} // Server-side rendering
//         infinite={false}
//         keyBoardControl={true}
//         customTransition="all .5"
//         transitionDuration={500}
//         containerClass={css.carouselContainer}
//         removeArrowOnDeviceType={['tablet', 'mobile']}
//         dotListClass={css.carouselDots}
//         itemClass={css.carouselItem}
//       >
//         {similarListings.map(listing => (
//           <div key={listing.id.uuid} style={{ marginRight: '10px' }}>
//             <ListingCard listing={listing} showAuthorInfo={true} config={config} />
//           </div>
//         ))}
//       </Carousel>
//     </div>
//   );
// };

// export default SimilarListingsCarousel;

// import React, { useState, useEffect } from 'react';
// import { FormattedMessage } from '../../util/reactIntl';
// import { ListingCard } from '../../components';
// import { useSwipeable } from 'react-swipeable';
// import css from './SimilarListingsCarousel.module.css';

// const SimilarListingsCarousel = ({ similarListings, config, title }) => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [itemsPerPage, setItemsPerPage] = useState(4);

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth <= 768) {
//         setItemsPerPage(2.5); // Changed from 1 to 2 for mobile view
//       } else {
//         setItemsPerPage(4);
//       }
//     };

//     handleResize();
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   const handlers = useSwipeable({
//     onSwipedLeft: () => onNextClick(),
//     onSwipedRight: () => onPrevClick(),
//     preventDefaultTouchmoveEvent: true,
//     trackMouse: true,
//   });

//   if (!similarListings || similarListings.length === 0) {
//     return null;
//   }

//   const onPrevClick = () => {
//     setCurrentIndex(prev => Math.max(prev - 1, 0));
//   };

//   const onNextClick = () => {
//     setCurrentIndex(prev => Math.min(prev + 1, similarListings.length - itemsPerPage));
//   };

//   return (
//     <div className={css.similarListingsSection}>
//       <h2 className={css.similarListingsTitle}>
//         <FormattedMessage id={title} />
//       </h2>
//       <div className={css.carouselContainer}>
//         <button className={css.carouselButton} onClick={onPrevClick} disabled={currentIndex === 0}>
//           &lt;
//         </button>
//         <div className={css.carouselWrapper} {...handlers}>
//           <div
//             className={css.carouselTrack}
//             style={{ transform: `translateX(-${currentIndex * (100 / itemsPerPage)}%)` }}
//           >
//             {similarListings.map(listing => (
//               <div
//                 key={listing.id.uuid}
//                 className={css.carouselItem}
//                 style={{ flex: `0 0 ${100 / itemsPerPage}%`, maxWidth: `${100 / itemsPerPage}%` }}
//               >
//                 <ListingCard listing={listing} showAuthorInfo={true} config={config} />
//               </div>
//             ))}
//           </div>
//         </div>
//         <button
//           className={css.carouselButton}
//           onClick={onNextClick}
//           disabled={currentIndex >= similarListings.length - itemsPerPage}
//         >
//           &gt;
//         </button>
//       </div>
//     </div>
//   );
// };

// export default SimilarListingsCarousel;

// import React, { useState, useEffect, useRef } from 'react';
// import { FormattedMessage } from '../../util/reactIntl';
// import { ListingCard } from '../../components';
// import css from './SimilarListingsCarousel.module.css';

// const SimilarListingsCarousel = ({ similarListings, config, title }) => {
//   const [itemsPerPage, setItemsPerPage] = useState(4);
//   const [isDragging, setIsDragging] = useState(false);
//   const [startX, setStartX] = useState(0);
//   const [scrollLeft, setScrollLeft] = useState(0);
//   const carouselRef = useRef(null);

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth <= 768) {
//         setItemsPerPage(2.5);
//       } else {
//         setItemsPerPage(4);
//       }
//     };

//     handleResize();
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   if (!similarListings || similarListings.length === 0) {
//     return null;
//   }

//   const handleDragStart = e => {
//     setIsDragging(true);
//     setStartX(e.type.includes('mouse') ? e.pageX : e.touches[0].clientX);
//     setScrollLeft(carouselRef.current.scrollLeft);
//   };

//   const handleDragEnd = () => {
//     setIsDragging(false);
//   };

//   const handleDrag = e => {
//     if (!isDragging) return;
//     e.preventDefault();
//     const x = e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;
//     const walk = (x - startX) * 2; // Scroll-speed
//     carouselRef.current.scrollLeft = scrollLeft - walk;
//   };

//   return (
//     <div className={css.similarListingsSection}>
//       <h2 className={css.similarListingsTitle}>
//         <FormattedMessage id={title} />
//       </h2>
//       <div
//         className={css.carouselWrapper}
//         ref={carouselRef}
//         onMouseDown={handleDragStart}
//         onMouseLeave={handleDragEnd}
//         onMouseUp={handleDragEnd}
//         onMouseMove={handleDrag}
//         onTouchStart={handleDragStart}
//         onTouchEnd={handleDragEnd}
//         onTouchMove={handleDrag}
//       >
//         <div className={css.carouselTrack}>
//           {similarListings.map(listing => (
//             <div
//               key={listing.id.uuid}
//               className={css.carouselItem}
//               style={{ flex: `0 0 ${100 / itemsPerPage}%`, maxWidth: `${100 / itemsPerPage}%` }}
//             >
//               <ListingCard listing={listing} showAuthorInfo={true} config={config} />
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SimilarListingsCarousel;

// import React, { useState, useEffect, useRef } from 'react';
// import { FormattedMessage } from '../../util/reactIntl';
// import { ListingCard } from '../../components';
// import css from './SimilarListingsCarousel.module.css';

// const SimilarListingsCarousel = ({ similarListings, config, title }) => {
//   const [itemsPerPage, setItemsPerPage] = useState(4);
//   const [isDragging, setIsDragging] = useState(false);
//   const [startX, setStartX] = useState(0);
//   const [scrollLeft, setScrollLeft] = useState(0);
//   const carouselRef = useRef(null);

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth <= 768) {
//         setItemsPerPage(2.5);
//       } else {
//         setItemsPerPage(4);
//       }
//     };

//     handleResize();
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   if (!similarListings || similarListings.length === 0) {
//     return null;
//   }

//   const handleDragStart = e => {
//     setIsDragging(true);
//     setStartX(e.type.includes('mouse') ? e.pageX : e.touches[0].clientX);
//     setScrollLeft(carouselRef.current.scrollLeft);
//   };

//   const handleDragEnd = () => {
//     setIsDragging(false);
//   };

//   const handleDrag = e => {
//     if (!isDragging) return;
//     e.preventDefault();
//     const x = e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;
//     const walk = (x - startX) * 2; // Scroll-speed
//     carouselRef.current.scrollLeft = scrollLeft - walk;
//   };

//   const handlePrevClick = () => {
//     carouselRef.current.scrollLeft -= carouselRef.current.offsetWidth;
//   };

//   const handleNextClick = () => {
//     carouselRef.current.scrollLeft += carouselRef.current.offsetWidth;
//   };

//   return (
//     <div className={css.similarListingsSection}>
//       <h2 className={css.similarListingsTitle}>
//         <FormattedMessage id={title} />
//       </h2>
//       <div className={css.carouselContainer}>
//         <button className={css.carouselButton} onClick={handlePrevClick}>
//           &lt;
//         </button>
//         <div
//           className={css.carouselWrapper}
//           ref={carouselRef}
//           onMouseDown={handleDragStart}
//           onMouseLeave={handleDragEnd}
//           onMouseUp={handleDragEnd}
//           onMouseMove={handleDrag}
//           onTouchStart={handleDragStart}
//           onTouchEnd={handleDragEnd}
//           onTouchMove={handleDrag}
//         >
//           <div className={css.carouselTrack}>
//             {similarListings.map(listing => (
//               <div
//                 key={listing.id.uuid}
//                 className={css.carouselItem}
//                 style={{ flex: `0 0 ${100 / itemsPerPage}%`, maxWidth: `${100 / itemsPerPage}%` }}
//               >
//                 <ListingCard listing={listing} showAuthorInfo={true} config={config} />
//               </div>
//             ))}
//           </div>
//         </div>
//         <button className={css.carouselButton} onClick={handleNextClick}>
//           &gt;
//         </button>
//       </div>
//     </div>
//   );
// };

// export default SimilarListingsCarousel;

// import React, { useState, useEffect, useRef } from 'react';
// import { FormattedMessage } from '../../util/reactIntl';
// import { ListingCard } from '../../components';
// import css from './SimilarListingsCarousel.module.css';

// const SimilarListingsCarousel = ({ similarListings, config, title }) => {
//   const [itemsPerPage, setItemsPerPage] = useState(4);
//   const [isMobile, setIsMobile] = useState(false);
//   const carouselRef = useRef(null);
//   const isDragging = useRef(false);
//   const startX = useRef(0);
//   const scrollLeft = useRef(0);

//   useEffect(() => {
//     const handleResize = () => {
//       const mobile = window.innerWidth <= 768;
//       setIsMobile(mobile);
//       setItemsPerPage(mobile ? 2.5 : 6);
//     };

//     handleResize();
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   if (!similarListings || similarListings.length === 0) {
//     return null;
//   }

//   const handlePrevClick = () => {
//     const slideWidth = carouselRef.current.offsetWidth;
//     carouselRef.current.scrollBy({ left: -slideWidth, behavior: 'smooth' });
//   };

//   const handleNextClick = () => {
//     const slideWidth = carouselRef.current.offsetWidth;
//     carouselRef.current.scrollBy({ left: slideWidth, behavior: 'smooth' });
//   };

//   const handleDragStart = e => {
//     if (isMobile) return;
//     isDragging.current = true;
//     startX.current = e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;
//     scrollLeft.current = carouselRef.current.scrollLeft;
//   };

//   const handleDragEnd = () => {
//     isDragging.current = false;
//   };

//   const handleDragMove = e => {
//     if (!isDragging.current || isMobile) return;
//     e.preventDefault();
//     const x = e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;
//     const walk = (x - startX.current) * 2;
//     carouselRef.current.scrollLeft = scrollLeft.current - walk;
//   };

//   return (
//     <div className={css.similarListingsSection}>
//       <h2 className={css.similarListingsTitle}>
//         <FormattedMessage id={title} />
//       </h2>
//       <div className={css.carouselContainer}>
//         {!isMobile && (
//           <button className={css.carouselButton} onClick={handlePrevClick}>
//             &lt;
//           </button>
//         )}
//         <div
//           className={css.carouselWrapper}
//           ref={carouselRef}
//           onMouseDown={handleDragStart}
//           onMouseLeave={handleDragEnd}
//           onMouseUp={handleDragEnd}
//           onMouseMove={handleDragMove}
//           onTouchStart={handleDragStart}
//           onTouchEnd={handleDragEnd}
//           onTouchMove={handleDragMove}
//         >
//           <div className={css.carouselTrack}>
//             {similarListings.map(listing => (
//               <div
//                 key={listing.id.uuid}
//                 className={css.carouselItem}
//                 style={{
//                   flex: `0 0 ${100 / itemsPerPage}%`,
//                   maxWidth: `${100 / itemsPerPage}%`,
//                   width: `${100 / itemsPerPage}%`,
//                 }}
//               >
//                 <ListingCard listing={listing} showAuthorInfo={true} config={config} />
//               </div>
//             ))}
//           </div>
//         </div>
//         {!isMobile && (
//           <button className={css.carouselButton} onClick={handleNextClick}>
//             &gt;
//           </button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default SimilarListingsCarousel;

import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ListingCard } from '../../components';
import css from './SimilarListingsCarousel.module.css';

const SimilarListingsCarousel = ({ similarListings, config, title }) => {
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [isMobile, setIsMobile] = useState(false);
  const carouselRef = useRef(null); // Always use refs at the top level
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);
  const startY = useRef(0); // Declare ref for Y-axis movement to track vertical scroll

  // This effect manages the resize event listener to adjust items per page
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      setItemsPerPage(mobile ? 2.5 : 6);
    };

    handleResize(); // Call it immediately to set initial state
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize); // Clean up
  }, []); // This effect only runs on mount/unmount

  if (!similarListings || similarListings.length === 0) {
    return null;
  }

  // Event handler for previous button click
  const handlePrevClick = () => {
    const slideWidth = carouselRef.current.offsetWidth;
    carouselRef.current.scrollBy({ left: -slideWidth, behavior: 'smooth' });
  };

  // Event handler for next button click
  const handleNextClick = () => {
    const slideWidth = carouselRef.current.offsetWidth;
    carouselRef.current.scrollBy({ left: slideWidth, behavior: 'smooth' });
  };

  // Start drag (on mousedown or touchstart)
  const handleDragStart = e => {
    if (isMobile) return;
    isDragging.current = true;
    startX.current = e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;
    startY.current = e.type.includes('mouse') ? e.pageY : e.touches[0].clientY; // Track Y-axis start point
    scrollLeft.current = carouselRef.current.scrollLeft;
  };

  // End drag (on mouseup or touchend)
  const handleDragEnd = () => {
    isDragging.current = false;
  };

  // Handle drag movement (on mousemove or touchmove)
  const handleDragMove = e => {
    if (!isDragging.current || isMobile) return;

    const x = e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;
    const y = e.type.includes('mouse') ? e.pageY : e.touches[0].clientY;

    // Calculate horizontal and vertical distances moved
    const walkX = (x - startX.current) * 2;
    const walkY = y - startY.current;

    // If vertical movement is more than horizontal, stop dragging
    if (Math.abs(walkY) > Math.abs(walkX)) {
      isDragging.current = false;
      return;
    }

    // Prevent default if horizontal movement exceeds vertical movement
    e.preventDefault();
    carouselRef.current.scrollLeft = scrollLeft.current - walkX;
  };

  return (
    <div className={css.similarListingsSection}>
      <h2 className={css.similarListingsTitle}>
        <FormattedMessage id={title} />
      </h2>
      <div className={css.carouselContainer}>
        {!isMobile && (
          <button className={css.carouselButton} onClick={handlePrevClick}>
            &lt;
          </button>
        )}
        <div
          className={css.carouselWrapper}
          ref={carouselRef}
          onMouseDown={handleDragStart}
          onMouseLeave={handleDragEnd}
          onMouseUp={handleDragEnd}
          onMouseMove={handleDragMove}
          onTouchStart={handleDragStart}
          onTouchEnd={handleDragEnd}
          onTouchMove={handleDragMove}
        >
          <div className={css.carouselTrack}>
            {similarListings.map(listing => (
              <div
                key={listing.id.uuid}
                className={css.carouselItem}
                style={{
                  flex: `0 0 ${100 / itemsPerPage}%`,
                  maxWidth: `${100 / itemsPerPage}%`,
                  width: `${100 / itemsPerPage}%`,
                }}
              >
                <ListingCard listing={listing} showAuthorInfo={true} config={config} />
              </div>
            ))}
          </div>
        </div>
        {!isMobile && (
          <button className={css.carouselButton} onClick={handleNextClick}>
            &gt;
          </button>
        )}
      </div>
    </div>
  );
};

export default SimilarListingsCarousel;
