import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ListingCard, UserCard } from '../../components';
import css from './FeaturedSellers.module.css';

const SellerCarousel = ({ listings, config }) => {
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [isMobile, setIsMobile] = useState(false);
  const carouselRef = useRef(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      setItemsPerPage(mobile ? 2.5 : 2);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDragStart = e => {
    if (isMobile) return;
    isDragging.current = true;
    startX.current = e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;
    scrollLeft.current = carouselRef.current.scrollLeft;
  };

  const handleDragEnd = () => {
    isDragging.current = false;
  };

  const handleDragMove = e => {
    if (!isDragging.current || isMobile) return;
    e.preventDefault();
    const x = e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;
    const walk = (x - startX.current) * 2;
    carouselRef.current.scrollLeft = scrollLeft.current - walk;
  };

  const handlePrevClick = () => {
    const slideWidth = carouselRef.current.offsetWidth;
    carouselRef.current.scrollBy({ left: -slideWidth, behavior: 'smooth' });
  };

  const handleNextClick = () => {
    const slideWidth = carouselRef.current.offsetWidth;
    carouselRef.current.scrollBy({ left: slideWidth, behavior: 'smooth' });
  };

  return (
    <div className={css.carouselContainer}>
      {!isMobile && (
        <button
          className={`${css.carouselButton} ${css.carouselButtonPrev}`}
          onClick={handlePrevClick}
        >
          &lt;
        </button>
      )}
      <div
        className={css.carouselWrapper}
        ref={carouselRef}
        onMouseDown={handleDragStart}
        onMouseLeave={handleDragEnd}
        onMouseUp={handleDragEnd}
        onMouseMove={handleDragMove}
        onTouchStart={handleDragStart}
        onTouchEnd={handleDragEnd}
        onTouchMove={handleDragMove}
      >
        <div className={css.carouselTrack}>
          {listings.map(listing => (
            <div
              key={listing.id.uuid}
              className={css.carouselItem}
              style={{ flex: `0 0 ${100 / itemsPerPage}%`, maxWidth: `${100 / itemsPerPage}%` }}
            >
              <ListingCard
                listing={listing}
                showAuthorInfo={false}
                config={config}
                sellerDetail={true}
              />
            </div>
          ))}
        </div>
      </div>
      {!isMobile && (
        <button
          className={`${css.carouselButton} ${css.carouselButtonNext}`}
          onClick={handleNextClick}
        >
          &gt;
        </button>
      )}
    </div>
  );
};

const SellerCard = ({ seller, config, onContactUser, currentUser }) => (
  <div className={css.sellerCard}>
    <div className={css.sellerInfo}>
      {/* <h3 className={css.sellerName}>{seller.attributes.profile.displayName}</h3>
      <button className={css.messageButton}>
        <FormattedMessage id="FeaturedSellers.messageSeller" />
      </button> */}
      <UserCard
        user={seller}
        sellerDetail={true}
        onContactUser={onContactUser}
        currentUser={currentUser}
      />
    </div>
    <SellerCarousel listings={seller.listings} config={config} />
  </div>
);

// const FeaturedSellers = ({ sellers, config, onContactUser, currentUser }) => {
//   return (
//     <div className={css.featuredSellersSection}>
//       <h2 className={css.featuredSellersTitle}>
//         <FormattedMessage id="FeaturedSellers.title" />
//       </h2>
//       <div className={css.sellersContainer}>
//         {sellers.map(seller => (
//           <SellerCard
//             key={seller.id.uuid}
//             seller={seller}
//             config={config}
//             onContactUser={onContactUser}
//             currentUser={currentUser}
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

const FeaturedSellers = ({ sellers, config, onContactUser, currentUser }) => {
  return (
    <div className={css.featuredSellersSection}>
      <h2 className={css.featuredSellersTitle}>
        <FormattedMessage id="FeaturedSellers.title" />
      </h2>
      <div className={css.sellersContainer}>
        {sellers.map(seller => (
          <SellerCard key={seller.id.uuid} seller={seller} config={config} />
        ))}
      </div>
    </div>
  );
};

export default FeaturedSellers;
